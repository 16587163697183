import _defineProperty from "/builds/u5yGSXwH/11/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback } from 'react';
import { searchResultsRoute, useRouter } from '@src/routes';
import { getValidSearch } from '@utils/searchUtils';
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser';
export var useSelectorToolSearch = function useSelectorToolSearch() {
  var router = useRouter();

  var _useCurrentUser = useCurrentUser(),
      getSitePreference = _useCurrentUser.getSitePreference;

  var handleSelectorSearch = useCallback(function (query, term, selectedFacets) {
    var currentOrgId = getSitePreference(SitePreference.CatalogFilter);
    var isStructureSearch = router.asPath.includes('structure-search');
    var queryTerm = '';
    if (isStructureSearch) queryTerm = 'structure-search';else if (term) queryTerm = term;else queryTerm = '';
    var focus = query.focus,
        type = query.type,
        sort = query.sort,
        perpage = query.perpage,
        format = query.format,
        filters = query.filters,
        image = query.image,
        catalogType = query.catalogType,
        region = query.region;
    var constantSearchTerms = {
      term: queryTerm,
      selectedFacets: selectedFacets,
      focus: focus,
      type: type,
      sort: sort,
      perpage: perpage,
      format: format,
      filters: filters,
      image: image,
      catalogType: catalogType,
      region: region
    };
    router.push("/search/[searchTerm]".concat(getValidSearch(_objectSpread(_objectSpread({}, constantSearchTerms), {}, {
      selectedFacets: selectedFacets,
      page: 1,
      orgId: currentOrgId
    }))), "".concat(searchResultsRoute.searchResults(queryTerm)).concat(getValidSearch(_objectSpread(_objectSpread({}, constantSearchTerms), {}, {
      selectedFacets: selectedFacets,
      page: 1,
      orgId: currentOrgId
    }))));
    window.scrollTo(0, 0);
  }, [router]);
  return [handleSelectorSearch];
};